import { combineReducers } from 'redux';
import * as constants from '../core/constants';

import baseReducer, {
  autoshipByDefault,
  auth,
  authUrl,
  defaultFrequencies,
  eligibilityGroups,
  environment,
  firstOrderPlaceDate,
  incentives,
  locale,
  merchantId,
  nextUpcomingOrder,
  optedin as coreOptedin,
  optedout,
  offerId,
  previewStandardOffer,
  previewUpsellOffer,
  productToSubscribe,
  sessionId,
  templates,
  prepaidShipmentsSelected
} from '../core/reducer';
import {
  getFirstSellingPlan,
  hasShopifySellingPlans,
  isOgFrequency,
  mapFrequencyToSellingPlan,
  safeProductId,
  getMatchingProductIfExists
} from '../core/utils';

import { getObjectStructuredProductPlans } from '../core/adapters';

import {
  sellingPlanAllocationsReducer,
  getSellingPlans,
  DEFAULT_PAY_AS_YOU_GO_GROUP_NAME
} from './reducers/productPlans';

const overrideLineKey = (state, productId, newValue) => {
  const keys = Object.keys(state).filter(it => it.startsWith(productId.toString()));
  if (keys.length) {
    return { ...state, ...keys.reduce((acc, cur) => ({ ...acc, [cur]: newValue }), {}) };
  }
  return state;
};

export const getDefaultSellingPlan = (sellingPlans, frequenciesEveryPeriod, defaultFrequency) => {
  if (!defaultFrequency) {
    return null;
  }

  if (!isOgFrequency(defaultFrequency)) {
    return defaultFrequency;
  }

  if (hasShopifySellingPlans(sellingPlans, frequenciesEveryPeriod)) {
    const sellingPlan = mapFrequencyToSellingPlan(sellingPlans, frequenciesEveryPeriod, defaultFrequency);

    if (sellingPlan) {
      return sellingPlan;
    }

    return getFirstSellingPlan(sellingPlans);
  }

  return defaultFrequency;
};

export const mapExistingOptinsFromOfferResponse = (state, offerEl) =>
  state.map(it => {
    if (isOgFrequency(it?.frequency)) {
      return {
        ...it,
        frequency: hasShopifySellingPlans(offerEl?.config?.frequencies, offerEl?.config?.frequenciesEveryPeriod)
          ? mapFrequencyToSellingPlan(
              offerEl?.config?.frequencies,
              offerEl?.config?.frequenciesEveryPeriod,
              it.frequency
            ) ||
            mapFrequencyToSellingPlan(
              offerEl?.config?.frequencies,
              offerEl?.config?.frequenciesEveryPeriod,
              offerEl.defaultFrequency
            ) ||
            getFirstSellingPlan(offerEl?.config?.frequencies)
          : it.frequency
      };
    }

    return it;
  });

export const reduceNewOptinsFromOfferResponse = (
  { autoship = {}, autoship_by_default = {}, default_frequencies = {}, in_stock = {} },
  existingOptins,
  offerEl
) =>
  Object.keys(autoship).reduce((acc, id) => {
    if (!existingOptins.some(it => it.id === id)) {
      if (!(autoship[id] && autoship_by_default[id] && in_stock[id])) return acc;
      const { config: { frequencies: sellingPlans, frequenciesEveryPeriod } = {}, defaultFrequency } = offerEl;
      const psdf = default_frequencies[id];
      let frequency;

      if (default_frequencies[id] && hasShopifySellingPlans(sellingPlans, frequenciesEveryPeriod)) {
        frequency =
          mapFrequencyToSellingPlan(sellingPlans, frequenciesEveryPeriod, `${psdf.every}_${psdf.every_period}`) ||
          getDefaultSellingPlan(sellingPlans, frequenciesEveryPeriod, defaultFrequency) ||
          getFirstSellingPlan(sellingPlans);
      } else if (default_frequencies[id]) {
        frequency = `${psdf.every}_${psdf.every_period}`;
      } else {
        frequency = getDefaultSellingPlan(sellingPlans, frequenciesEveryPeriod, defaultFrequency) || '_'; // Placeholder to be backfilled in SETUP_PRODUCT reducer
      }

      return acc.concat({
        id,
        frequency
      });
    }

    return acc;
  }, []);

const getOGSellingPlanGroup = product => {
  const productSpecificFrequencySellingPlanGroup = product?.selling_plan_groups.find(
    isProductSpecificFrequencySellingPlanGroup
  );

  return productSpecificFrequencySellingPlanGroup || getDefaultSubscriptionSellingPlanGroup(product);
};

const getDefaultSubscriptionSellingPlanGroup = product => {
  return product?.selling_plan_groups.find(group => group.name === DEFAULT_PAY_AS_YOU_GO_GROUP_NAME);
};

const isProductSpecificFrequencySellingPlanGroup = group => group.name.startsWith('og_psfl');

const productOrVariantInStockReducer = (acc, cur) => ({
  ...overrideLineKey(acc, cur.id, cur.available),
  [cur.id]: cur.available
});

const productTrue = (acc, [id]) => ({ ...acc, [id]: true });

const reduceProductCartLine = (acc, cur) => {
  const productId = safeProductId(cur.key);
  return { ...acc, [cur.key]: acc[productId] || null };
};

export const autoshipEligible = (state = {}, action) => {
  if (constants.RECEIVE_PRODUCT_PLANS === action.type) {
    return Object.entries(action.payload).reduce(productTrue, state);
  }
  if (constants.SETUP_CART === action.type) {
    const { payload: cart } = action;
    return cart.items.reduce(reduceProductCartLine, state);
  }
  if (constants.SETUP_PRODUCT === action.type) {
    const {
      payload: { product }
    } = action;
    const defaultSellingPlanGroup = getDefaultSubscriptionSellingPlanGroup(product);
    const sellingPlanIdsInDefaultGroup = new Set(
      defaultSellingPlanGroup?.selling_plans.map(sellingPlan => sellingPlan.id) ?? []
    );
    return [product, ...(product?.variants || [])]?.reduce((acc, cur) => {
      const productSellingPlansFromDefaultGroup =
        cur?.selling_plan_allocations?.filter(sellingPlan =>
          sellingPlanIdsInDefaultGroup.has(sellingPlan.selling_plan_id)
        ) ?? [];

      // a product is autoship eligible if it has plans from the default "Subscribe and Save" selling plan group
      // the presence of other selling plans (prepaid, product-specific frequencies) does not mean it is autoship eligible
      const isAutoshipEligible = productSellingPlansFromDefaultGroup.length > 0;

      return {
        ...overrideLineKey(acc, cur.id, isAutoshipEligible),
        [cur.id]: isAutoshipEligible
      };
    }, state);
  }
  if (constants.SET_PREVIEW_STANDARD_OFFER === action.type) {
    if (action.payload.isPreview !== true) return state;
    return { ...state, ...{ [action.payload.productId]: true } };
  }
  return state;
};

export function textToFreq(text) {
  const period = ['day', 'week', 'month'].findIndex(it => text.toLowerCase().includes(it)) + 1;
  const every = (text.match(/(\d+)/) || ['', 1])[1];
  if (every && period) {
    return `${every}_${period}`;
  }
  return null;
}

export function sellingPlansToEveryPeriod(sellingPlanGroup) {
  return sellingPlanGroup?.selling_plans
    ?.map(({ options }) => options || [])
    .flat()
    .map(({ value }) => textToFreq(value));
}

export function sellingPlansToText(sellingPlanGroup, frequencies) {
  return sellingPlanGroup.options?.[0]?.values || frequencies;
}

export function sellingPlansToFrequencies(sellingPlanGroup) {
  return sellingPlanGroup?.selling_plans?.map(({ id }) => `${id}`);
}

function getPrepaidShipments(sellingPlan) {
  const shipments = sellingPlan?.options.find(({ name }) => name === 'Shipment amount')?.value.split(' ')[0];
  return shipments ? Number(shipments) : undefined;
}

function getPrepaidSellingPlans(prepaidSellingPlanGroups) {
  return prepaidSellingPlanGroups.reduce((acc, cur) => {
    const variant = cur.name.split('-')[1];

    const sellingPlanInfo = cur.selling_plans.map(sellingPlanObject => {
      return {
        numberShipments: getPrepaidShipments(sellingPlanObject),
        sellingPlan: String(sellingPlanObject.id)
      };
    });

    return { ...acc, [variant]: sellingPlanInfo };
  }, {});
}

export const config = (
  state = {
    frequencies: [],
    offerType: 'radio',
    frequenciesEveryPeriod: []
  },
  action
) => {
  if (constants.RECEIVE_PRODUCT_PLANS === action.type) {
    const frequencies = [...new Set(Object.values(action.payload).map(Object.keys).flat())];
    return {
      ...state,
      frequencies
    };
  }

  if (constants.SETUP_PRODUCT === action.type) {
    const {
      payload: { product, currency }
    } = action;
    let configToAdd = {};
    // pay as you go selling plans
    const sellingPlanGroup = getOGSellingPlanGroup(product);
    const frequencies = sellingPlansToFrequencies(sellingPlanGroup);
    if (frequencies?.length) {
      const frequenciesEveryPeriod = sellingPlansToEveryPeriod(sellingPlanGroup);
      const frequenciesText = sellingPlanGroup.options?.[0]?.values || frequencies;
      let defaultFrequency = state.defaultFrequency;

      if (defaultFrequency && isOgFrequency(defaultFrequency)) {
        defaultFrequency =
          mapFrequencyToSellingPlan(frequencies, frequenciesEveryPeriod, defaultFrequency) ||
          getFirstSellingPlan(frequencies) ||
          defaultFrequency;
      }
      configToAdd = {
        frequencies,
        frequenciesEveryPeriod,
        frequenciesText,
        ...(defaultFrequency ? { defaultFrequency } : {}),
        hasProductSpecificFrequencies:
          state.hasProductSpecificFrequencies || isProductSpecificFrequencySellingPlanGroup(sellingPlanGroup)
      };
    }

    // prepaid selling plans
    const prepaidSellingPlanGroups = product?.selling_plan_groups.filter(group => /^Prepaid-.*/.test(group.name));
    if (prepaidSellingPlanGroups.length) {
      configToAdd = {
        ...configToAdd,
        prepaidSellingPlans: { ...state.prepaidSellingPlans, ...getPrepaidSellingPlans(prepaidSellingPlanGroups) }
      };
    }
    return {
      ...state,
      ...configToAdd,
      storeCurrency: currency
    };
  }

  if (constants.RECEIVE_OFFER === action.type) {
    const {
      payload: { offer: offerEl }
    } = action;
    const {
      defaultFrequency,
      config: { frequencies: sellingPlans, frequenciesEveryPeriod, prepaidSellingPlans = {} } = {},
      product
    } = offerEl;

    // We don't want to be setting the default frequency to a prepaid selling plan
    if (prepaidSellingPlans[product?.id]?.some(({ sellingPlan }) => sellingPlan === defaultFrequency)) {
      return { ...state, defaultFrequency: getFirstSellingPlan(sellingPlans) || defaultFrequency };
    }

    if (!isOgFrequency(defaultFrequency))
      return {
        ...state,
        defaultFrequency: defaultFrequency
      };

    return {
      ...state,
      defaultFrequency:
        mapFrequencyToSellingPlan(sellingPlans, frequenciesEveryPeriod, defaultFrequency) ||
        getFirstSellingPlan(sellingPlans) ||
        defaultFrequency
    };
  }

  if (constants.RECEIVE_MERCHANT_SETTINGS === action.type) {
    return {
      ...state,
      merchantSettings: {
        ...action.payload
      }
    };
  }

  return state;
};

export const inStock = (state = {}, action) => {
  if (constants.RECEIVE_PRODUCT_PLANS === action.type) {
    return Object.entries(action.payload).reduce(productTrue, state);
  }

  if (constants.SETUP_CART === action.type) {
    const cart = action.payload;

    return cart.items.reduce(reduceProductCartLine, state);
  }

  if (constants.SETUP_PRODUCT === action.type) {
    const {
      payload: { product }
    } = action;
    return [product, ...(product?.variants ?? [])]?.reduce(productOrVariantInStockReducer, state) || state;
  }
  // force offer to refresh when requesting a new one
  if (constants.REQUEST_OFFER === action.type && action.payload.product === null) {
    return { ...state };
  }
  if (constants.SET_PREVIEW_STANDARD_OFFER === action.type) {
    if (action.payload.isPreview !== true) return state;
    return { ...state, ...{ [action.payload.productId]: true } };
  }
  return state;
};

export const offer = (state = {}, _action) => state;

function getFrequencyForPrepaidShipments({ prepaidShipments, offer: offerEl, product }) {
  if (prepaidShipments) {
    const productId = safeProductId(product.id);
    const plan = offerEl.config.prepaidSellingPlans[productId]?.find(p => p.numberShipments === prepaidShipments);
    return plan ? plan.sellingPlan : null;
  }
  return offerEl.config.frequencies[0];
}

function getOptedInItem(cartItem) {
  const prepaidShipments = getPrepaidShipments(cartItem.selling_plan_allocation.selling_plan);
  const item = {
    id: cartItem.key,
    frequency: `${cartItem.selling_plan_allocation.selling_plan.id}`
  };
  if (prepaidShipments) {
    item.prepaidShipments = prepaidShipments;
  }
  return item;
}

export const optedin = (state = [], action) => {
  if (constants.SETUP_CART === action.type) {
    const cart = action.payload;
    return state
      .filter(it => !it.id.includes(':'))
      .concat(cart.items.reduce((acc, cur) => (cur.selling_plan_allocation ? [...acc, getOptedInItem(cur)] : acc), []));
  }

  if (constants.RECEIVE_OFFER === action.type) {
    const { offer: offerEl = {}, ...payload } = action.payload;
    const existingOptins = mapExistingOptinsFromOfferResponse(state, offerEl);
    const newOptins = reduceNewOptinsFromOfferResponse(payload, existingOptins, offerEl);

    return [...existingOptins, ...newOptins];
  }

  if (constants.SETUP_PRODUCT === action.type) {
    const { product } = action.payload;
    const sellingPlanGroup = getOGSellingPlanGroup(product);
    if (!sellingPlanGroup) {
      return state;
    }
    const frequencies = sellingPlansToFrequencies(sellingPlanGroup);
    const frequenciesEveryPeriod = sellingPlansToEveryPeriod(sellingPlanGroup);

    return state.map(curr => {
      if (isOgFrequency(curr.frequency)) {
        return {
          ...curr,
          frequency:
            mapFrequencyToSellingPlan(frequencies, frequenciesEveryPeriod, curr.frequency) ||
            getFirstSellingPlan(frequencies)
        };
      }

      return curr;
    });
  }

  if (constants.PRODUCT_CHANGE_PREPAID_SHIPMENTS === action.type) {
    const { payload } = action;
    // core reducer sets prepaid shipments
    const newState = coreOptedin(state, action);
    // get the new frequency (selling plan) that matches the prepaid shipments
    const [oldone, rest] = getMatchingProductIfExists(newState, payload.product);
    return rest.concat({
      ...oldone,
      ...payload.product,
      frequency: getFrequencyForPrepaidShipments(payload)
    });
  }

  return coreOptedin(state, action);
};

export const productOffer = (state = {}, _action) => state;

export const productPlans = (state = {}, action) => {
  if (constants.SETUP_PRODUCT === action.type) {
    const {
      payload: { product, currency }
    } = action;

    const sellingPlans = getSellingPlans(product);

    return (
      // We consider the product here as well for cases where we don't have any variants
      [product, ...(product?.variants ?? [])]?.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.id]: cur.selling_plan_allocations?.reduce(
            (accumulator, current) => sellingPlanAllocationsReducer(accumulator, current, sellingPlans, currency),
            []
          )
        }),
        state
      ) || state
    );
  }
  if (constants.SETUP_CART === action.type) {
    const cart = action.payload;
    return (
      cart.items.reduce(
        (acc, cur) =>
          cur.selling_plan_allocation
            ? {
                ...acc,
                [cur.key]: sellingPlanAllocationsReducer([], cur.selling_plan_allocation, [], cart.currency)
              }
            : acc,
        state
      ) || state
    );
  }
  if (constants.RECEIVE_PRODUCT_PLANS === action.type) {
    return getObjectStructuredProductPlans(action.payload);
  }
  return state;
};

const reducer = combineReducers({
  auth,
  authUrl,
  autoshipByDefault,
  autoshipEligible,
  config,
  defaultFrequencies,
  eligibilityGroups,
  environment,
  firstOrderPlaceDate,
  incentives,
  inStock,
  locale,
  merchantId,
  nextUpcomingOrder,
  offer,
  offerId,
  optedin,
  optedout,
  previewStandardOffer,
  previewUpsellOffer,
  productOffer,
  productPlans,
  productToSubscribe,
  sessionId,
  templates,
  prepaidShipmentsSelected
});

export default function shopifyReducer(state, action) {
  if (window.og && window.og.previewMode) return baseReducer(state, action);
  return reducer(state, action);
}
