import { throttle } from 'throttle-debounce';
import { requestSessionId } from './actions';
import { LOCAL_STORAGE_CHANGE, LOCAL_STORAGE_CLEAR } from './constants';

export const STORE_ROOT = 'OG_STATE';

export const safeParseState = serializedState => {
  try {
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};
const isPreviewMode = () => window.og && window.og.previewMode;

export const loadState = () => (isPreviewMode() ? {} : safeParseState(localStorage.getItem(STORE_ROOT)));

export const serializeState = state => {
  if (!state || !state.sessionId) {
    return false;
  }
  return JSON.stringify({
    sessionId: state.sessionId,
    optedin: state.optedin,
    optedout: state.optedout,
    productOffer: state.productOffer,
    firstOrderPlaceDate: state.firstOrderPlaceDate,
    productToSubscribe: state.productToSubscribe
    // uncomment this to see parameter saved to localStorage
    // inStock: state.inStock,
    // autoshipEligible: state.autoshipEligible,
    // productOffer: state.productOffer
    // frequency: { ...state.frequency }
  });
};

export const saveState = state => {
  if (isPreviewMode()) return;
  const serializedState = serializeState(state);
  if (serializedState && localStorage.getItem(STORE_ROOT) !== serializedState) {
    localStorage.setItem(STORE_ROOT, serializedState);
  }
};

export const listenLocalStorageChanges = store =>
  throttle(500, ev => {
    if (isPreviewMode()) return;
    const { key, newValue } = ev;
    if (key === STORE_ROOT && newValue === null) {
      store.dispatch({ type: LOCAL_STORAGE_CLEAR });
      setTimeout(() => store.dispatch(requestSessionId()), 0);
    } else if (key === STORE_ROOT) {
      store.dispatch({
        type: LOCAL_STORAGE_CHANGE,
        newValue: safeParseState(newValue)
      });
    }
  });

export const clearState = () => localStorage.removeItem(STORE_ROOT);
