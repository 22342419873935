import { html, css } from 'lit-element';
import { productChangeFrequency } from '../core/actions';
import { withChildOptions } from '../core/resolveProperties';
import { FrequencyStatus, mapStateToProps, frequencyText } from './FrequencyStatus';
import { connect } from '../core/connect';
import { frequencyToSellingPlan } from '../core/utils';

export const frequencyEquals = (a, b) => {
  if (a === null || b === null) return false;
  return a.every === b.every && a.period === b.period;
};

export class SelectFrequency extends withChildOptions(FrequencyStatus) {
  static get properties() {
    return {
      ...super.properties,
      defaultText: { type: String, attribute: 'default-text' }
    };
  }

  /**
   * // css customization i.e.
   * og-select-frequency {
   *  background: black;
   *  color: orange;
   *  border-radius: 0;
   *  font-weight: bold;
   *  font-family: Impact;
   *  --og-select-padding: 10px 30px 10px 10px;
   *  font-size: 20px;
   * }
   */
  static get styles() {
    return css`
      :host {
        display: inline-block;
        cursor: pointer;
        background-color: var(--og-select-bg-color, #fff);
        border: var(--og-select-border, 1px solid #aaa);
        border-radius: var(--og-select-border-radius, 0.5em);
        border-width: var(--og-select-border-width, 1px);
        box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
        z-index: 1;
      }
    `;
  }

  set defaultFrequency(val) {
    this._defaultFrequency = val;
  }

  // default frequency comes from redux store first, then default option value, and then finally attribute value.
  get defaultFrequency() {
    const { options, isSelected } = this.childOptions;
    let result;

    if (this.productDefaultFrequency) {
      result = this.productDefaultFrequency;
    } else if (isSelected) {
      result = isSelected;
    } else if (options.length) {
      result = options[0].value;
    } else {
      result = this._defaultFrequency;
    }

    // this runs for shopify selling plans translated to freq
    if (this.config?.frequencies?.length && result && this.config?.frequenciesEveryPeriod?.length) {
      return frequencyToSellingPlan(result, this.config);
    }

    return result;
  }

  get currentFrequency() {
    if (this.frequency) {
      return this.frequency;
    }
    return this.defaultFrequency;
  }

  productChangeFrequency(_, value) {
    this.frequency = value;
  }

  render() {
    let options;
    const defaultFrequency = this.defaultFrequency;

    if (this.frequencies?.length) {
      options = this.frequencies.map((value, ix) => {
        let text;
        if (this.config.frequenciesEveryPeriod && ix in this.config.frequenciesEveryPeriod) {
          text = frequencyText(this.config.frequenciesEveryPeriod[ix], defaultFrequency);
        } else if (this.frequenciesText && ix in this.frequenciesText) {
          text = this.frequenciesText[ix];
        } else {
          text = frequencyText(value, this.defaultFrequency);
        }
        return { value, text };
      });
    } else {
      ({ options } = this.childOptions);
    }

    if (!options.length) {
      options = (this.frequencies || []).map(value => ({
        value,
        text: frequencyText(value, defaultFrequency)
      }));
    }

    options = options.map(({ text, value }) => ({
      text:
        value === defaultFrequency
          ? html`
              ${text} ${this.defaultText || ''}
            `
          : text,
      value
    }));

    return html`
      <og-select
        ariaLabel="Delivery frequency"
        .options="${options}"
        .selected="${this.currentFrequency}"
        .onChange="${({ target: { value } }) => {
          this.productChangeFrequency(this.product, value, this.offer);
        }}"
      ></og-select>
    `;
  }
}

export const ConnectedSelectFrequency = connect(mapStateToProps, {
  productChangeFrequency
})(SelectFrequency);

export default ConnectedSelectFrequency;
