import { parseFrequency } from './api';

/**
 * Convert the current redux state to purchase
 * post compatible json
 */
export const getProductsForPurchasePost = (state = {}, productIds = []) =>
  (state.optedin || [])
    .map(optin => {
      const purchasePostObject = {
        product: optin.id,
        subscription_info: {
          components: optin.components || []
        },
        tracking_override: {
          offer: ((state.productOffer || {})[optin.id] || [])[0],
          ...parseFrequency(optin.frequency)
        }
      };
      if (state.firstOrderPlaceDate && state.firstOrderPlaceDate[optin.id]) {
        purchasePostObject.subscription_info.first_order_place_date = state.firstOrderPlaceDate[optin.id];
      }
      if (state.productToSubscribe && state.productToSubscribe[optin.id]) {
        purchasePostObject.tracking_override.product = state.productToSubscribe[optin.id];
      }
      return purchasePostObject;
    })
    .filter(optin => optin.tracking_override.offer)
    .filter(optin => (productIds.length ? productIds.includes(optin.product) : optin));

/**
 * Convert the old redux state from productPlans that was array structured:
 * {
 *    '1_2': ['$15.00', '10%', '$13.50'],
 *    '2_2': ['$15.00', '10%', '$13.50']
 * }
 * to a object structured one that can hold more information with keys:
 * [
 *   {
 *     frequency: '1_2',
 *     prepaidShipments: '3' or null,
 *     regularPrice: '$15.00',
 *     subscriptionPrice: '$13.50',
 *     discountRate: '10%'
 *   },
 * ]
 */
export const getObjectStructuredProductPlans = (productPlans = {}) => {
  const adaptedProductPlans = {};

  Object.entries(productPlans).forEach(([productVariant, productPlanOnArrayStructure]) => {
    Object.entries(productPlanOnArrayStructure).forEach(([frequencyAsKey, arrayPrices]) => {
      let newProductPlanStructure = {};
      if (arrayPrices && !Array.isArray(arrayPrices)) {
        newProductPlanStructure = arrayPrices;
      } else {
        newProductPlanStructure = {
          frequency: frequencyAsKey,
          prepaidShipments: null,
          regularPrice: arrayPrices[0],
          subscriptionPrice: arrayPrices[2],
          discountRate: arrayPrices[1]
        };
      }

      if (adaptedProductPlans[productVariant]) {
        adaptedProductPlans[productVariant].push(newProductPlanStructure);
      } else {
        adaptedProductPlans[productVariant] = [newProductPlanStructure];
      }
    });
  });

  return adaptedProductPlans;
};

export default { getProductsForPurchasePost, getObjectStructuredProductPlans };
