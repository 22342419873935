import { html, css } from 'lit-element';
import { fetchOrders } from '../core/actions';
import { connect } from '../core/connect';
import { auth } from '../core/props';
import { withProduct } from '../core/resolveProperties';
import { TemplateElement } from '../core/base';

export class UpsellButton extends withProduct(TemplateElement) {
  static get styles() {
    return css`
      :host[hidden] {
        display: none;
      }
      :host {
        display: inline-block;
      }
    `;
  }

  static get properties() {
    return {
      ...super.properties,
      upcomingOrderDate: {
        type: String,
        attribute: false
      },
      auth,
      isPreview: { type: Boolean, attribute: false },
      target: { type: String }
    };
  }

  constructor() {
    super();
    this.fetchOrders = () => 0;
    this.addEventListener('click', this.handleClick.bind(this));
  }

  updated(changed) {
    if (changed.has('auth') && this.auth && !this.upcomingOrderDate && !this.isPreview) {
      this.fetchOrders();
    }
  }

  handleClick() {
    let modal;
    if (!this.target && this.offer) {
      // TODO remove offer shadowRoot built in code.
      modal = this.offer.querySelector('og-upsell-modal');
      if (!modal) {
        modal = this.offer.shadowRoot.querySelector('og-upsell-modal');
      }
    } else if (this.target) {
      modal = document.querySelector(this.target);
    } else {
      throw Error('You must specify a target attribute or place this element as child of og-offer');
    }
    if (modal) {
      modal.setAttribute('show', true);
    }
  }

  render() {
    return html`
      <slot>
        <og-next-upcoming-order></og-next-upcoming-order>
      </slot>
    `;
  }
}

export const mapStateToProps = state => ({
  isPreview: state.previewUpsellOffer
});

export const ConnectedUpsellButton = connect(mapStateToProps, { fetchOrders })(UpsellButton);

export default UpsellButton;
